<div class="boels-input-phone">
  <mat-form-field appearance="fill" floatLabel="auto" [subscriptSizing]="subscriptSizing" [hideRequiredMarker]="true">
    @if (inputLabel) {
      <mat-label>
        {{ inputLabel }}
        @if (!required && !inputHideOptionalMarker) {
          <span class="optional-label"> - {{ 'forms.controlIsOptional' | translate }}</span>
        }
      </mat-label>
    }
    <button
      type="button"
      mat-button
      [matMenuTriggerFor]="menu"
      class="country-selector"
      [disabled]="control.disabled"
      (menuClosed)="onFocus()"
    >
      <span class="country-selector-flag flag" [ngClass]="selectedCountry?.flagClass"></span>
      @if (selectedCountry?.dialCode) {
        <span class="country-selector-code">+{{ selectedCountry.dialCode }}</span>
      }
    </button>
    <mat-menu
      #menu="matMenu"
      class="boels-input-phone__mat-menu-panel"
      backdropClass="ngx-mat-tel-input-overlay-backdrop"
      overlayPanelClass="ngx-mat-tel-input-overlay-pane"
    >
      @if (inputEnableSearch) {
        <input
          class="country-search"
          [(ngModel)]="countrySearchValue"
          type="text"
          [placeholder]="inputSearchPlaceholder"
          (click)="$event.stopPropagation()"
        />
      }
      @for (country of preferredCountriesInDropDown; track country) {
        <button type="button" mat-menu-item class="country-list-button" (click)="onCountrySelect(country)">
          <div class="icon-wrapper">
            <div class="flag" [ngClass]="country.flagClass"></div>
          </div>
          <div class="label-wrapper">
            {{ country.name }}
            @if (country?.dialCode) {
              <span>+{{ country.dialCode }}</span>
            }
          </div>
        </button>
      }
      @if (preferredCountriesInDropDown?.length) {
        <mat-divider></mat-divider>
      }
      @for (country of allCountries; track country) {
        @if (country | countrySearch: countrySearchValue) {
          <button type="button" mat-menu-item class="country-list-button" (click)="onCountrySelect(country)">
            <div class="icon-wrapper">
              <div class="flag" [ngClass]="country.flagClass"></div>
            </div>
            <div class="label-wrapper">{{ country.name }} +{{ country.dialCode }}</div>
          </button>
        }
      }
    </mat-menu>
    <input
      #focusableElement
      matInput
      boelsInputPhoneFormat
      type="tel"
      [qa]="'boels-input-phone-input'"
      [autofocus]="inputAutofocus"
      [tabindex]="inputTabindex"
      [placeholder]="placeholder"
      [selectedCountryCode]="selectedCountryCode"
      [inputFormat]="inputFormat"
      [formControl]="control"
      (blur)="onBlur()"
    />
    <ng-content></ng-content>
    @if (control.value && !control.disabled) {
      <div matSuffix class="right-buttons-mat-suffix-wrap">
        <button mat-mini-fab class="mdc-fab--extra-mini" type="reset" (click)="clearControl()" aria-label="Clear">
          <mat-icon svgIcon="closebox"></mat-icon>
        </button>
      </div>
    }
  </mat-form-field>
</div>
